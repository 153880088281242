// @import "node_modules/gutenberg-css/scss/gutenberg";

/*!
 * Gutenberg
 *
 * MIT Fabien Sa
 * https://github.com/BafS/Gutenberg
 */

// Variables
@import "node_modules/gutenberg-css/scss/variables";

// Normalize
@import "vendors/normalize";

@import "node_modules/gutenberg-css/scss/print-reset";
@import "node_modules/gutenberg-css/scss/pagination";
@import "node_modules/gutenberg-css/scss/page";
@import "node_modules/gutenberg-css/scss/reformat";
@import "node_modules/gutenberg-css/scss/utilities";

@import "node_modules/gutenberg-css/scss/themes/modern";
